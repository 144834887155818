#bookings-list .actions .p-button.p-button-icon-only.p-button-rounded {
    height: 2rem;
}
.p-datepicker.p-component.celebrate td:not(.p-datepicker-other-month) .p-disabled {
    color: red;
    font-weight: bold;
}
#podUpdateRegister.p-badge {
    font-size: 0.7rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
}
.additionalInfoCheckbox label {
    top: 5px;
    position: relative;
    left: 10px;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
    margin-left: 0.5rem;
}

.p-column-filter-menu-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-menu-button:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: #e9ecef;
    color: #343a40;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #EEF2FF;
    color: #4338CA;
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-column-filter-clear-button {
    width: 2rem;
    height: 2rem;
    color: #6c757d;
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-column-filter-clear-button:hover {
    color: #343a40;
    border-color: transparent;
    background: #e9ecef;
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #C7D2FE;
}

.p-column-filter-overlay {
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: 0.75rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: 0.75rem 1.25rem;
    border: 0 none;
    color: #495057;
    background: transparent;
    transition: box-shadow 0.2s;
    border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #4338CA;
    background: #EEF2FF;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #495057;
    background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #C7D2FE;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid #dee2e6;
    color: #343a40;
    background: #f8f9fa;
    margin: 0;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: 1.25rem;
    border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: 0.75rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: 1.25rem;
}

.p-column-filter-overlay.p-component.p-fluid.p-column-filter-overlay-menu {
    background: white;
}

.p-column-filter-menu-button.p-column-filter-menu-button,
.p-column-filter-menu-button.p-column-filter-menu-button:hover,
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover,
.p-column-filter-clear-button.p-link {
    display: none !important;
}

#bookings-list .p-column-filter-row .p-inputtext.p-component.p-column-filter {
    padding: 0.25rem
}

#reports-event-datatable .p-sortable-column-badge {
    display: none;
}

.p-datatable-scrollable th.p-frozen-column {
    position: sticky !important;
}

.p-datatable .center .p-column-header-content {
    text-align: center;
    display: block !important;
}

.defaultColors .p-dropdown-label.p-inputtext {
    position: relative;
    bottom: 5px;
}

.fc.fc-theme-standard,
table.fc-scrollgrid {
    height: calc(100vh - 130px);
}

a.fc-daygrid-event {
    cursor: pointer;
}

a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
    cursor: pointer;
}

a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end:hover {
    background: #1565C0;
    color: white;
    font-weight: bold;
}